import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/SEO"
import SimpleBanner from "../../components/SimpleBanner/SimpleBanner"
import { Link } from "gatsby"

const Container = styled.div`
  max-width: 80ch;
  margin: 80px auto 0px;
  text-align: left;
  padding: 1em;

  h2 {
    color: var(--primary);
    font-size: var(--h2);
  }

  .link-container {
    color: var(--primary);
    font-weight: bold;
    text-align: center;
  }

  span {
    color: var(--primary);
  }

  p {
    line-height: 1.6em;
  }
`

const organisation_item = () => {
  return (
    <>
      <Seo title="Napis LOVE" />
      <Layout>
        <SimpleBanner title="Napis LOVE">
          <StaticImage
            className="banner__image desktop"
            src="../../images/organisation/love.jpg"
          />
          <StaticImage
            className="banner__image mobile"
            src="../../images/organisation/love-mobile.jpg"
          />
        </SimpleBanner>
        <Container>
          <p>
            Niepowtarzalne litery świetlne LOVE zapewnią wyjątkową atmosferę
            każdej uroczystości. Napis uświetni wiele wydarzeń takich jak:
          </p>
          <ul>
            <li>śluby</li>
            <li>wesela</li>
            <li>sesje ślubne</li>
            <li>walentynki</li>
            <li>zaręczyny</li>
            <li>ozdoba imprez tematycznych</li>
          </ul>
          <p>
            Napis po rozłożeniu ma wymiary 4 metrów szerokości ( wys : 125 cm (
            jedna literka ) szer : 95 cm (jedna literka)). Biały, elegancki
            doda efektu glamur każdej uroczystości i stworzy niesamowity klimat.
            Usługa jest kompleksowa.
          </p>
          <p className="link-container">
            <Link to="/contact">Zapytaj nas o szczegóły</Link>
          </p>
        </Container>
      </Layout>
    </>
  )
}

export default organisation_item
